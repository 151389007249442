// custom dependencies
import AOS from 'aos';
// import svgInjector from 'svg-injector-2';
import objectFitImages from 'object-fit-images';
import Cookie from 'js-cookie';
// import _ from 'underscore';
import Headroom from "headroom.js";
// import shufflejs from 'shufflejs';
import imagesloaded from 'imagesloaded';

/* RET DET HER - SKAL IKKE LOADES FRA BUNDLE - ALLE MODULER ER MED */
import Swiper from 'swiper';
  // import Swiper styles
  // import 'swiper/swiper-bundle.css';
  // import Swiper bundle with all modules installed
  //import Swiper from 'swiper/bundle';

import lozad from 'lozad';

// import Chart from "chart.js";
// import inView from "in-view";

// import Isotope from 'isotope-layout'; // use  yarn add isotope-layout


const deps = {
  AOS: AOS,
  // svgInjector: svgInjector,
  objectFitImages: objectFitImages,
  Cookie: Cookie,
  // _:_
  Headroom: Headroom,
  // shuffle: shufflejs,
  imagesloaded:imagesloaded,
  Swiper: Swiper,
  // Chart:Chart,
  // inView:inView
  lozad: lozad,
};

export default deps;
